<template>
  <footer class="clearfix">
    <div>
      <p>
        {{ $t('footer.riskWarn') }}
      </p>
      <div class="line"></div>
      <img src="@/assets/images/footer_logo.png" />
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import '@/assets/css/layout/footer.scss';
</style>

<template>
  <div>
    <el-container :key="reRender" class="wrapper">
      <el-aside :width="!matches ? center : ''" v-show="!isRestrictSidebar">
        <slide-bar
          menu-click-outside="click"
          :isCollapse.sync="isCollapse"
          :onOpen.sync="onOpen"
          :matches="matches"
          @matchesClose="matchesClose"
        ></slide-bar>
      </el-aside>
      <el-container class="is-vertical">
        <v-header
          :matches="matches"
          :isRestrictSidebar="isRestrictSidebar"
          :onOpen.sync="onOpen"
          :isCollapse.sync="isCollapse"
          @openMenu="openMenu"
        ></v-header>
        <el-main>
          <router-view></router-view>
          <el-footer>
            <v-footer></v-footer>
          </el-footer>
          <SessionDialog />
        </el-main>
      </el-container>
      <CarouselBanner v-if="showPopupBanner" />
    </el-container>
    <div class="agreement-dialog">
      <AgreementDialog @agreementExecuted="getAgreementExecuted" />
    </div>
  </div>
</template>

<script>
import vHeader from '@/components/Header';
import vFooter from '@/components/Footer';
import slideBar from '@/components/Menu';
import SessionDialog from '@/components/SessionDialog.vue';
import AgreementDialog from '@/components/AgreementDialog/AgreementDialog.vue';
import { apiSetlanguage } from '@/resource';
import CarouselBanner from '../../src/components/CarouselBanner.vue';

export default {
  name: 'layout',
  data() {
    return {
      reRender: 0,
      center: '260px',
      matches: false,
      smallMatches: false,
      isCollapse: false,
      onOpen: true,
      showPopupBanner: false,
      agreementExecuted: false
    };
  },
  components: { vHeader, vFooter, slideBar, SessionDialog, AgreementDialog, CarouselBanner },
  computed: {
    lang() {
      return this.$store.state.common.lang;
    },
    isRestrictSidebar() {
      return this.$route.meta.restrictSideBar || false;
    }
  },
  watch: {
    lang: {
      immediate: true,
      async handler(val) {
        this.$i18n.locale = val;
        if (this.$store.state.common.loginStatus) {
          await this.setUserLanguagePreference(val);
          this.reRender++;
        }
      }
    },
    onOpen(bool) {
      this.isCollapse = !bool;
    },
    isCollapse(bool) {
      if (!bool && this.onOpen) this.center = '260px';
      else if (!this.matches) this.center = '64px';
      else this.center = '0';
    },
    matches(bool) {
      if (bool) {
        this.onOpen = false;
        this.center = '0';
        this.isCollapse = false;
      } else {
        this.onOpen = true;
        this.center = '260px';
      }
    },
    smallMatches(bool) {
      if (bool) {
        this.onOpen = false;
      }
    },
    $route: {
      handler: function (route) {
        this.setShowPopupBanner(route.path);
        // if (route.path == '/home' || route.path == '/downloads') {
        //   this.showPopupBanner = true;
        // } else {
        //   this.showPopupBanner = false;
        // }
      }
    }
  },
  mounted() {
    const vm = this;
    vm.checkSize();
    if (this.$route.path == '/home') {
      this.showPopupBanner = true;
    } else {
      this.showPopupBanner = false;
    }
    $(window).resize(function() {
      vm.checkSize();
    });
  },
  methods: {
    setShowPopupBanner(routePath) {
      let arr = ['/home', '/downloads'];
      if (arr.includes(routePath) && this.agreementExecuted) {
        this.showPopupBanner = true;
      } else {
        this.showPopupBanner = false;
      }
    },
    getAgreementExecuted() {
      console.log('layoutlayoutlayoutlayoutlayoutlayout');
      this.agreementExecuted = true;
      this.setShowPopupBanner(this.$route.path);
    },
    checkSize() {
      let w = window.innerWidth;

      if (w <= 1023 && w >= 768) {
        this.smallMatches = window.matchMedia('(max-width: 1023px)').matches;
        this.matches = false;
      } else if (w < 768) {
        this.matches = window.matchMedia('(max-width: 768px)').matches;
        this.smallMatches = false;
      } else {
        this.matches = false;
        this.smallMatches = false;
      }
    },
    async setUserLanguagePreference(lang) {
      await apiSetlanguage({ language: lang });
    },
    openMenu() {
      this.isCollapse = !this.isCollapse;
      this.onOpen = !this.onOpen;
    },
    matchesClose() {
      this.onOpen = !this.onOpen;
      this.isCollapse = false;
    }
  }
};
</script>

<style lang="scss">
@import '@/assets/css/app.scss';
</style>

<template>
  <div id="agreement">
    <vDialog :show.sync="visible" width="90%" class="dialog">
      <div>
        <div class="share-account" v-if="isSubUser">
          <div class="title">{{ $t('agreement.title') }}</div>
          <div class="account rebate-account">
            {{ $t('agreement.rebateAccount') }} :<span>{{ ibAccount }}</span>
          </div>
          <div class="content">{{ $t('agreement.confirmAgreement') }}</div>
        </div>
        <div class="ordinary-account" v-else>
          <div class="title">{{ $t('agreement.title') }}</div>
          <div class="account rebate-account">
            {{ $t('agreement.rebateAccount') }} :<span>{{ ibAccount }}</span>
          </div>
          <div class="agreement"  v-loading.lock="pdfScreenLoading">
            <vue-pdf-embed  @progress="loadProgress"  v-if="showPreViewPdf" ref="previewPdfRef" :source="fileUrl" :width="pdfAreaWidth" />
          </div>
          <div class="description">
            {{ $t('agreement.description') }}
          </div>
          <div class="agree" >
            <el-button class="el-button btn-blue" size="small" @click="agreeAgreement">{{
              $t('agreement.agree')
            }}</el-button>
          </div>
        </div>
      </div>
    </vDialog>
  </div>
</template>

<script>
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed.js';
import vDialog from '@/components/vDialog.vue';
import { apiAgreement, apiConfirmAgreement } from '@/resource';
import Bus from '@/assets/js/Bus.js';
export default {
  components: { vDialog, VuePdfEmbed },
  data() {
    return {
      visible: false,
      fileName: '',
      fileUrl: '',
      numPages: null,
      showPreViewPdf: true,
      pdfScreenLoading:false
    };
  },
  watch: {
    ibAccount(newValue, oldValue) {
      if (oldValue != newValue) {
        this.getAgreement();
      }
    }
  },
  computed: {
    ibAccount() {
      return this.$store.state.behavior.AID;
    },
    isSubUser() {
      return this.$store.state.common.isSubUser;
    },
    pdfAreaWidth() {
      let maxWidth = this.currentElDialogWidth > 852 ? 852 : this.currentElDialogWidth;
      return this.currentElDialogWidth > 768 ? maxWidth : this.currentWidth;
    }
  },
  mounted() {
    // setTimeout(() => {
    this.getAgreement();
    // }, 3000);
  },
  methods: {
    loadProgress(progress){
      if(progress.loaded === progress.total){
        this.pdfScreenLoading = false;
      }else {
        this.pdfScreenLoading = true;
      }
    },
    getAgreement() {
      if (this.ibAccount) {
        apiAgreement(this.ibAccount).then(res => {
          if (res.data.code === 0) {
            if (res.data.data && res.data.data.status === 1 && res.data.data.fileUrl) {
              this.fileUrl = `/api` + res.data.data.fileUrl;
              // this.getNumPages();
              this.visible = true;
            } else {
              this.visible = false;
              this.emitAgreementExecuted();
            }
          }
        });
      }
    },
    agreeAgreement() {
      apiConfirmAgreement(this.ibAccount).then(res => {
        if (res.data.code === 0) {
          this.visible = false;
          this.emitAgreementExecuted();
        }
      }).finally(()=>{
        Bus.$emit('updateAgreement', ()=>{
          this.getAgreement();
        })
      });
    },
    emitAgreementExecuted() {
      Bus.$emit('agreementExecuted', true);
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/components/sessionDialog.scss';
.dialog {
  .el-button {
    width: 100%;
  }
}
.title {
  font-size: 20px;
  font-weight: 700;
}
.rebate-account {
  span {
    color: #004cb2;
  }
}
.share-account {
  text-align: center;
  .account,
  .content {
    margin-top: 30px;
    font-size: 18px;
    span {
      color: #004cb2;
    }
  }
}
.ordinary-account {
  @include rtl-sass-value(text-align, left, right);
  .account {
    margin-top: 10px;
    span {
      color: #004cb2;
    }
  }
  .agreement {
    margin: 20px 0;
    overflow-y: scroll;
  }
  .agree {
    margin-top: 20px;
  }
}
@media (min-width: 768px) {
  .agreement {
    height: 50vh;
  }
}
@media (max-width: 768px) {
  .agreement {
    height: 40vh;
  }
}
</style>

<template>
  <div id="menu">
    <div class="menu-shadow" v-show="matches && onOpen" @click="matchesClose"></div>
    <div class="menu-wrapper" :style="menu_wrapperStyle">
      <div class="menu-logo" v-if="(!isCollapse || matches) && onOpen">
        <img alt class="logo" />
        <img
          v-if="matches"
          src="@/assets/images/header/scrollClose.png"
          alt
          @click="matchesClose"
          class="menu-back"
          data-testid="nav_back"
        />
      </div>
      <div class="menu-logo-small" v-else>
        <router-link to="/home" class="logo">
          <img alt class="logo-img" />
        </router-link>
      </div>
      <el-scrollbar style="height: 100%;">
        <el-collapse-transition>
          <el-menu
            class="el-menu-vertical"
            background-color="#ffffff"
            text-color="rgb(0, 76, 178)"
            active-text-color="rgb(0, 76, 178)"
            :router="true"
            :unique-opened="true"
            :default-active="$route.path"
            :collapse="isCollapse && !matches"
          >
            <div v-for="(menu, index) in $config.getMenus(regulator)" v-bind:key="index">
              <el-menu-item
                :index="menu.path"
                @click="checkMatchesClose"
                v-if="!menu.children"
                :data-testid="menu.path"
              >
                <div class="icon" :style="{ backgroundImage: `url(${menu.icon})` }"></div>
                <span slot="title" @click="menuClick(menu.path)" :data-testid="`click_${menu.path}`">
                  {{ $t(menu.name) }}
                </span>
                <div class="line"></div>
              </el-menu-item>
              <el-submenu :index="menu.path" v-if="menu.children">
                <template slot="title">
                  <div class="icon" :style="{ backgroundImage: `url(${menu.icon})` }"></div>
                  <span slot="title">{{ $t(menu.name) }}</span>
                  <div class="line"></div>
                </template>
                <el-menu-item
                  v-for="(children, index) in menu.children"
                  @click="checkMatchesClose"
                  v-bind:key="index"
                  :index="children.path"
                >
                  <div class="icon" :style="{ backgroundImage: `url(${children.icon})` }"></div>
                  {{ $t(children.name) }}
                  <div class="line"></div>
                </el-menu-item>
              </el-submenu>
            </div>
          </el-menu>
        </el-collapse-transition>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import helper from '@/util/signinHelper';

export default {
  name: 'vMenu',
  props: { isCollapse: Boolean, matches: Boolean, onOpen: Boolean },
  data() {
    return { bodyDirection: null };
  },
  methods: {
    matchesClose() {
      this.$emit('matchesClose');
    },
    checkMatchesClose() {
      const vm = this;
      this.$nextTick(function() {
        if (vm.matches) vm.matchesClose();
      });
    },
    redirectToCp() {
      helper.signOut();
    },
    menuClick(url) {
      if (this.isCollapse) this.$router.push({ path: url });
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.bodyDirection = document.body.dir;
    });
  },
  computed: {
    menu_wrapperStyle() {
      const menuWidth = { width: this.matches == false && this.isCollapse ? '64px' : '260px' };
      const direction = this.bodyDirection === 'rtl' ? { right: this.menuPosition } : { left: this.menuPosition };
      return { ...direction, ...menuWidth };
    },
    menuPosition() {
      return this.matches && !this.onOpen ? '-260px' : '0px';
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/layout/menu.scss';
</style>

<template>
  <header>
    <ul class="clearfix">
      <li class="fl">
        <img
          src="@/assets/images/header/scrollClose.png"
          class="header-btn"
          id="menu-open-button"
          @click="showMenu"
          v-show="isShowCloseMenu"
          alt="close menu"
          data-testid="closeMenu"
        />
        <img
          src="@/assets/images/header/scroll.png"
          class="header-btn"
          id="menu-open-button"
          @click="showMenu"
          v-show="isShowOpenMenu"
          alt="open menu"
          data-testid="openMenu"
        />

        <img
          v-show="isRestrictSidebar"
          src="@/assets/images/pug/logo.png"
          class="logo-image"
          alt="logo"
          data-testid="logo"
        />
        <!-- 通知 -->
        <HeaderNotification v-if="agreementExecuted"></HeaderNotification>
      </li>
      <li class="fr">
        <el-dropdown trigger="click" @command="langCommand" @visible-change="addLangStyle">
          <div class="lang-select">
            <img class="area-img" :src="language.icon" alt />
            <img v-if="openLangImg" class="down" src="@/assets/images/header/down_open.png" alt="" />
            <img v-else src="@/assets/images/header/down.png" alt="" />
          </div>
          <el-dropdown-menu slot="dropdown" class="lang-dropdown-box" placement="right-end">
            <el-dropdown-item
              v-for="item in languages"
              :key="item.value"
              :command="item"
              :class="{ active: lang == item.value }"
            >
              <img :src="item.icon" alt="" />
              <span>{{ item.label }}</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown trigger="click" @command="handleCommand" @visible-change="addInfoStyle">
          <div class="login-inner">
            <img src="@/assets/images/header/member_logo.png" class="member-logo" alt="" />
            <div class="user-name"><span class="name" v-html="userName"></span></div>
            <img v-if="openInfoImg" src="@/assets/images/header/down_open.png" alt="" />
            <img v-else src="@/assets/images/header/down.png" alt="" />
          </div>
          <el-dropdown-menu slot="dropdown" class="login-dropdown-box">
            <el-dropdown-item command="myProfile" class="myProfile">
              <div class="icon"></div>
              <span>{{ $t('menu.ibProfile') }}</span>
            </el-dropdown-item>
            <el-dropdown-item v-if="!isSubUser" command="backToClientPortal" class="login_back">
              <div class="icon"></div>
              <span>{{ $t('header.bckToCP') }}</span>
            </el-dropdown-item>
            <el-dropdown-item command="logout" class="logout">
              <div class="icon"></div>
              <span>{{ $t('header.logout') }}</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </li>
    </ul>
  </header>
</template>

<script>
import helper from '@/util/signinHelper';
import HeaderNotification from '@/components/notification/HeaderNotification';
import { sensorsRegister } from '@/util/sensorsdata';

import Bus from '@/assets/js/Bus.js';
export default {
  name: 'vHeader',
  components: { HeaderNotification },
  props: { matches: Boolean, onOpen: Boolean, isCollapse: Boolean, isRestrictSidebar: Boolean },
  data() {
    return {
      language: '',
      languages: [
        {
          value: 'en_US',
          label: 'English',
          icon: require('@/assets/images/header/en.png')
        },
        {
          value: 'fr_FR',
          label: 'Français',
          icon: require('@/assets/images/header/fr.png')
        },
        {
          value: 'es',
          label: 'Español',
          icon: require('@/assets/images/header/es.png')
        },
        {
          value: 'pt',
          label: 'Português',
          icon: require('@/assets/images/header/pt.png')
        },
        {
          value: 'ko',
          label: '한국어',
          icon: require('@/assets/images/header/ko.png')
        },
        {
          value: 'zh_CN',
          label: '中文',
          icon: require('@/assets/images/header/cn.png')
        },

        {
          value: 'ja',
          label: '日本語',
          icon: require('@/assets/images/header/ja.png')
        },
        {
          value: 'th',
          label: 'ภาษาไทย',
          icon: require('@/assets/images/header/th.png')
        },
        {
          value: 'vi',
          label: 'Tiếng Việt',
          icon: require('@/assets/images/header/vi.png')
        },
        {
          value: 'de',
          label: 'Deutsch',
          icon: require('@/assets/images/header/de.png')
        },
        {
          value: 'id',
          label: 'Indonesian',
          icon: require('@/assets/images/header/id.png')
        },
        {
          value: 'ar',
          label: 'عربي',
          icon: require('@/assets/images/header/ar.png')
        }
      ],
      openLangImg: false,
      openInfoImg: false,
      agreementExecuted: false
    };
  },
  watch: {
    language(lang) {
      this.lang = lang.value;
      document.body.dir = lang.value === 'ar' ? 'rtl' : 'ltr';
    },
    lang: {
      immediate: true,
      handler(val) {
        this.language = this.languages.find(f => f.value == val) || this.languages[0];
        this.$nextTick(function() {
          sensorsRegister({ current_lang: val });
          zE('messenger:set', 'locale', this.swithchPUGLang(val));
        });
        this.setFontFamily(val);
      }
    }
  },
  computed: {
    isSubUser() {
      return this.$store.state.common.isSubUser;
    },
    userName() {
      return this.isSubUser ? this.$store.state.common.subEmail : this.$store.state.common.userName;
    },
    lang: {
      get() {
        return this.$store.state.common.lang;
      },
      set(value) {
        return this.$store.commit('common/setLang', value);
      }
    },
    isShowOpenMenu() {
      if (this.isRestrictSidebar) return false;
      return this.isCollapse || this.matches;
    },
    isShowCloseMenu() {
      if (this.isRestrictSidebar) return false;
      return this.onOpen && !this.isCollapse;
    }
  },
  methods: {
    addLangStyle(bool) {
      this.openLangImg = bool;
    },
    addInfoStyle(bool) {
      this.openInfoImg = bool;
    },
    showMenu() {
      this.$emit('openMenu');
    },
    handleCommand(command) {
      if (command == 'backToClientPortal' || command == 'logout') helper.signOut(command);
      else if (command == 'myProfile') this.$router.push('/profile');
    },
    langCommand(command) {
      this.language = command;
    },
    swithchPUGLang(lang) {
      switch (lang) {
        case 'en_US':
          return 'en';
        case 'zh_CN':
          return 'zh-CN';
        case 'fr_FR':
          return 'fr';
        case 'ja':
          return 'ja';
        case 'ko':
          return 'ko';
        case 'vi':
          return 'vi';
        case 'th':
          return 'th';
        case 'es':
          return 'es';
        case 'pt':
          return 'pt';
        case 'de':
          return 'de';
        case 'id':
          return 'id';
        case 'ar':
          return 'ar';
        default:
          return 'en';
      }
    },
    setFontFamily(lang) {
      if (lang === 'vi') {
        document.body.style.setProperty('font-family', 'sans-serif', 'important');
      } else {
        document.body.style.setProperty('font-family', 'Avenir', 'important');
      }
    }
  },
  mounted() {
    Bus.$on('agreementExecuted', () => {
      this.agreementExecuted = true;
    });
    let callback = (val, oldVal, uri) => {
      console.log('localStorage change', val);
      this.$nextTick(() => {
        if (val != this.userName) location.reload();
      });
    };

    this.$ls.on('setUserName', callback); //watch change foo key and triggered callback
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/layout/header.scss';

.logo-image {
  width: 120px;
}
</style>
